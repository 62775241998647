.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .Container__Content__Wrapper {
    position: relative;
    padding: 0 2rem;
    width: 100%;
    height: 100%;
    max-width: 1400px;
  }

}
