@import '~antd/dist/antd.css';

body {
  font-family: 'Roboto', sans-serif;
}

.pos-relative {
  position: relative;
}

.h-100 {
  height: 100%;
}

.mt-2 {
  margin-top: 2rem;
}

.impressum {
  padding: 16px;
}

.d-flex {
  display: flex;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.flex-justify-center {
  justify-content: center;
}

.bg-gray {
  background-color: #e0dedb;
}

.bg-white {
  background-color: #fafafa;
}

.section-title {
  font-size: 2rem;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 2rem;
}

.cols-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    width: 100%;
    margin: 2rem 0;

    @media screen and (min-width: 800px) {
      width: calc(33.333333% - 2rem);
      margin: 1rem;
    }
  }

}

.Tag-List {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0.5rem 0;
  }
}

.Outlined-Button {
  color: #5a5a5a;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
}

.viviane_zoe {
  background-image: url("./img/viviane_zoe.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  padding-top: 120%;
}
