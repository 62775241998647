
.Hero {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('./../img/title_viviane.jpg');
  background-position: 25%;
  background-size: cover;

  .Hero__Content {
    position: absolute;
    bottom: 7.5%;
    left: 5%;
    right: 5%;
  }

  .Hero__Title, .Hero__Subtitle {
    color: #5a5a5a;
    text-align: right;
  }

  .Hero__Title {
    font-size: 2.2rem;
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
    line-height: 2.8rem;
    filter: drop-shadow(0px 2px 12px white);
  }

  .Hero__Subtitle {
    flex: 1;
    font-size: 0.8rem;
    color: #5a5a5a;
  }

  .Hero__CTA {
    margin-top: 35vh;
    display: flex;
    justify-content: center;

    .Hero__CTA__Button {
      width: 100%;
      max-width: 350px;
      margin-top: 2rem;
      margin-bottom: 2rem;

      color: #5a5a5a;
      border: 1px solid #5a5a5a;
      border-radius: 5px;
    }
  }

  @media (min-width: 600px) {
    max-height: 100vw;
    background-position: center center;
    background-image: url('./../img/viviane_800x.jpg');
  }
  @media (min-width: 800px) { //,  (min-height: 686px) {
    background-position: center center;
    background-image: url('./../img/viviane_1200x.jpg');
  }
  @media (min-width: 1200px), (min-height: 1028px) {
    background-position: center center;
    background-image: url('./../img/viviane_1800x.jpg');
  }
  @media (min-width: 1800px), (min-height: 1371px) {
    background-position: center center;
    background-image: url('./../img/viviane_2400x.jpg');
  }

  @media screen and (min-width: 600px) {
    .Hero__Content{
      bottom: 5%;
      left: unset;
      right: 5%;
      max-width: 50%;
    }

    .Hero__Title {
      font-size: 3.5rem;
      line-height: 4rem;
    }
    .Hero__Subtitle {
      font-size: 0.8rem;
      position: relative;
    }
    .Hero__CTA {
      margin-top: 2rem;
      justify-content: flex-end;
    }
  }
  @media screen and (min-width: 800px) {
    .Hero__Title {
      font-size: 4.5rem;
      line-height: 5rem;
    }
    .Hero__Subtitle {
      font-size: 1.1rem;
    }
  }
  @media screen and (min-width: 1200px) {
    .Hero__Title {
      font-size: 5rem;
      line-height: 5.5rem;
    }
    .Hero__Subtitle {
      font-size: 1.3rem;
    }
  }
  @media screen and (min-width: 1800px) {
    .Hero__Title {
      font-size: 5.75rem;
      line-height: 6.25rem;
    }
    .Hero__Subtitle {
      font-size: 1.55rem;
    }
  }
  @media screen and (min-width: 2400px) {
    .Hero__Title {
      font-size: 7rem;
      line-height: 7.5rem;
    }
    .Hero__Subtitle {
      font-size: 1.8rem;
    }
  }
}

.title-header-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 66%;

  width: 100%;
  top: 31%;
  bottom: 1rem;
  transform: unset;
}


