.Footer {
  height: 300px;
  display: flex;
  align-items: flex-end;

  background-color: #5a5a5a;
  color: white;

  .Footer__Impressum {
    text-align: center;
    margin-bottom: 3rem;

    a {
      color: white;
    }

    a:hover {
      color: lightgrey;
    }
  }
}
